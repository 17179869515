<template>
  <div>
    <el-row :gutter="10" type="flex" v-on:click.stop class="holiday-row">
      <el-col :span="8">
        <el-input
          :placeholder="__('Enter name')"
          v-model="schedule_item.item_name"
          @change="handleUpdateScheduleItem"
        ></el-input>
      </el-col>
      <el-col :span="12">
        <el-date-picker
          v-model="date_range"
          type="datetimerange"
          value-format="yyyy-MM-dd HH:mm"
          :format="displayDateTimeFormat"
          :start-placeholder="__('Start Date')"
          :end-placeholder="__('End Date')"
          style="min-width: 300px; width: 100%; "
          @change="handleUpdateScheduleItem()"
          :default-time="['00:00:00']"
        >
        </el-date-picker>
      </el-col>
      <el-col :span="4" style="min-width:170px">
        <el-checkbox
          v-model="is_repeat"
          @change="
            handleUpdateScheduleItem();
            handleUpdateItemRepeat();
          "
          >{{ __("Repeat") }}</el-checkbox
        >
        <el-button
          plain
          style="border: none; padding-left: 10px; padding-right: 10px"
          @click="handleRemoveScheduleItem"
        >
          <i class="el-icon-close" style="font-weight: bold"></i>
        </el-button>
        <el-button
          plain
          style="border: none; padding-left: 10px; padding-right: 10px"
          @click="toggleShowRepeat"
          v-show="is_repeat"
        >
          <i
            class="el-icon-arrow-up"
            v-show="is_repeat && show_repeat"
            style="font-weight: bold"
          ></i>
          <i
            class="el-icon-arrow-down"
            v-show="is_repeat && !show_repeat"
            style="font-weight: bold"
          ></i>
        </el-button>
      </el-col>
    </el-row>
    <el-row
      type="flex"
      v-show="show_repeat === true"
      style="padding-top: 10px; margin-left: 5px;"
    >
      <holiday-schedule-repeat-edit
        v-if="schedule_item.schedule_repeats"
        :min-date="schedule_item.to_date_time"
        :schedule-item-repeat="schedule_item.schedule_repeats"
        @update-schedule-repeat="
          schedule_repeat_new => handleUpdateScheduleRepeat(schedule_repeat_new)
        "
      ></holiday-schedule-repeat-edit>
    </el-row>
  </div>
</template>

<script>
import HolidayScheduleRepeatEdit from "@/components/ScheduleRepeatEdit";
import moment from "moment-timezone";
import { mapGetters } from "vuex";
//import _ from "lodash";

export default {
  name: "HolidayScheduleEdit",
  components: {
    HolidayScheduleRepeatEdit
  },
  props: {
    scheduleItem: {
      type: Object,
      required: false
    }
  },
  data() {
    return {
      date_range: ["", ""],
      show_repeat: false,
      is_repeat: false,
      schedule_item: []
    };
  },
  computed: {
    ...mapGetters("app", {
      displayDateTimeFormat: "displayDateTimeFormat"
    })
  },
  methods: {
    handleError(err) {
      console.log(err);
    },
    toggleShowRepeat() {
      this.show_repeat = !this.show_repeat;
    },
    handleRemoveScheduleItem() {
      this.$emit("remove-schedule-item");
    },
    handleUpdateItemRepeat() {
      this.show_repeat = this.is_repeat;
    },
    handleUpdateScheduleItem() {
      this.schedule_item.is_repeat = Number(this.is_repeat);

      if (this.date_range) {
        this.schedule_item.from_date_time = this.date_range[0];
        this.schedule_item.to_date_time = this.date_range[1];
      }

      if (this.is_repeat && this.schedule_item.schedule_repeats) {
        this.schedule_item.schedule_repeats.starts_on = this.schedule_item.from_date_time;
      }
      this.$emit("update-schedule-item", this.schedule_item);
    },
    handleUpdateScheduleRepeat(schedule_repeat_new) {
      this.schedule_item.schedule_repeats = schedule_repeat_new;
    }
  },
  mounted() {
    this.schedule_item = this.scheduleItem;

    let start_date = moment(this.schedule_item.from_date_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    let end_date = moment(this.schedule_item.to_date_time).format(
      "YYYY-MM-DD HH:mm:ss"
    );

    if (start_date) {
      this.schedule_item.from_date_time = start_date;
    }
    if (end_date) {
      this.schedule_item.to_date_time = end_date;
    }

    this.date_range = [start_date, end_date];

    this.is_repeat = Boolean(this.schedule_item.is_repeat);
  },

  updated() {
    if (!this.schedule_item.schedule_repeats && this.is_repeat) {
      this.schedule_item.schedule_repeats = {
        starts_on: this.schedule_item.from_date_time,
        repeat_frequency: "Daily",
        repeat_interval: "1",
        repeat_in: "DayOfMonth",
        end_type: "Never",
        end_type_value: "",
        repeat_summary: ""
      };
    }
  }
};
</script>

<style scoped lang="scss">
@import "~@/styles/node_common.scss";
.holiday-row {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
</style>
